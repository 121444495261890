import '../App.css';

import twitter from '../assets/images/twitter.svg'
import telegram from '../assets/images/telegram.svg'
import { ReactComponent as HeaderLogo } from '../assets/images/header_logo.svg'

function Navbar() {
  return (
    <div className='header'>
      <div className='container drop-in'>
        <div className='header-cnt-full'>
          <div className='header-cnt-left'>
          <HeaderLogo />
          </div>
          <div className='header-cnt-right'>
            <a className='app'  target='_blank' href='https://t.me/+TDh-DeOH660yNDlk'>Launch The App</a>
            <div className='hdr-icon'>
                <div className='social-icon'>
                  <a target='_blank' href='https://twitter.com/wixlink'>
                    <img src={twitter} alt="twitter" />
                  </a>
                </div>
                <div className='social-icon'>
                  <a target='_blank' href='https://t.me/+TDh-DeOH660yNDlk'>
                    <img src={telegram} alt="telegram" />
                  </a>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
