import '../App.css';

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import slider1 from '../assets/images/slider1.png'
import profile from '../assets/images/profile_icon.png'
import find from '../assets/images/find_icon.png'
import application from '../assets/images/application_img.png'
import robot from '../assets/images/robot_v.svg'
import box from '../assets/images/icon_box_bg.png'
import btmbg from '../assets/images/btm_bg.png'
import toprobot from '../assets/images/robot_top.png'
import robotmob from '../assets/images/robot_mob.png'
import robottopmob from '../assets/images/robot_mob_top.png'
import robotv2 from '../assets/images/robot_top_v2.png'
import toprobo from '../assets/images/top_robot.png'
import bodybg from '../assets/images/body_bg.png'
import iconbg from '../assets/images/icon_box_bg_v1.png'
import bodymobile from '../assets/images/body_mobile.png'
import mobrobot1 from '../assets/images/robot_mob_v1.png'
import app from '../assets/images/application_img_v1.png'
import sliderv1 from '../assets/images/slider_img1.png'
import sliderv2 from '../assets/images/slider_img2.png'



function Launch() {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
      <div className='launch-main'>
            <section className='wix-part-people'>
                <div className='container drop-in-2'>
                    <div className='people-content'>
                        <h1>A Telegram Social Extension for <span className='web-text'>web3
                                <span className='text-rotate'>
                                    <span>people</span>
                                    <span>events</span>
                                    <span>groups</span>
                                    <span>builders</span>
                                </span>
                                </span></h1>
                        <p>Expand Your Web3 Network and Navigate Events!</p>
                        <img className='launch-mobile' src={mobrobot1} alt="mobrobot1" />
                        <div className='btn-part'>
                            <a className='app' target='_blank' href='https://t.me/+TDh-DeOH660yNDlk'>Launch The App</a>
                            <a className='join' target='_blank' href='https://t.me/+TDh-DeOH660yNDlk'>Join Community</a>
                        </div>
                    </div>
                </div>
            </section>
            <section className='wix-part-two'>
                <div className='container reveal fade-bottom'>
                <div className='create-profile-block'>
                    <h2>Create a profile, connect with new people and access event details, all within Telegram.</h2>
                    <div className='left-block'>
                    <img src={profile} alt="profile" />
                    <h3>Build your profile</h3>
                    <p>Add extra attributes on top of your Telegram handle.</p>
                    </div>
                    <div className='right-block'>
                    <img src={find} alt="find" />
                    <h3>Find people</h3>
                    <p>Connect with people globally or in specific communities using smart filters.</p>
                    </div>
                </div>
                </div>
            </section>

            <section className='wix-application-part'>
                <div className='container reveal fade-left'>
                <div className='application-content'>
                    <div className='application-content-inner'>
                        <h3>You don’t need to install any application, <span>just run it!</span></h3>
                    <div className='full-part'>
                        <img className='application-img' src={app} alt="app" />
                        <div className='app-cnt-wrapper'>
                            <div className='app-cnt'>
                                <h5>Build your profile in a snap and embark on your journey of discovery.</h5>
                                <a className='btn' target='_blank' href='https://t.me/+TDh-DeOH660yNDlk'>Launch It</a>
                            </div>
                            <div className='launch-mobile slider'>
                                <Slider {...settings}>
                                <div>
                                    <img src={sliderv1} alt="sliderv1" />
                                </div>
                                <div>
                                    <img className='mobile-show' src={sliderv2} alt="sliderv2" />
                                </div>
                                </Slider>
                            </div>
                        </div>
                     </div>
                    </div>
                </div>
                </div>
            </section>

            <section className='wix-touch-part'>
                <div className='container reveal fade-right'>
                <div className='touch-content'>
                    <div className='touch-content-inner'>
                        <h3>Looking to boost your event or community networking?</h3>
                        <div className='btn'>
                            <a href="mailto:info@wixlink@xyz">Get In Touch</a>
                        </div>
                        <img className='launch-mobile' src={robotmob} alt="robotmob" />
                    </div>
                </div>
                </div>
            </section>
      </div>
    );
  }
  
  export default Launch;


  function reveal() {
    var reveals = document.querySelectorAll(".reveal");
  
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;
  
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }
  
  window.addEventListener("scroll", reveal);