import '../App.css';

import { ReactComponent as Ftrlogo } from '../assets/images/ftr_logo.svg'
import twitter from '../assets/images/twitter.svg'
import telegram from '../assets/images/telegram.svg'


function Footer() {
    return (
      <div className='footer'>
        <section className='footer-part'>
          <div className='container'>
            <div className='footer-cnt'>
              <div className='ftr-left'>
                <Ftrlogo />
                <div className="ftr-icon">
                  <div className='social-icon'>
                    <a target='_blank' href='https://twitter.com/wixlink'>
                      <img src={twitter} alt="twitter" />
                    </a>
                  </div>
                  <div className='social-icon'>
                    <a target='_blank' href='https://t.me/+TDh-DeOH660yNDlk'>
                      <img src={telegram} alt="telegram" />
                    </a>
                  </div>
                </div>
              </div>
              <div className='ftr-right'>
                <ul>
                  <li>© 2023 Wixlink</li>
                  <li>
                    <a href="#">privacy policy</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
      
    );
  }
  
  export default Footer;
  